/* 
 * @Author: 曹俊杰 
 * @Date: 2022-04-26 16:18:35
 * @Module:签署设置 
 */
 <template>
  <div class="electronicDocumentsLayouts">
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.electronicDocumentsLayouts {
  background: #f7f8f9;
  .content {
    // background: #fff;
    margin: 0 auto;
    width: 1200px;
    min-height: 100vh;
  }
}
</style>